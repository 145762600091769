import React from "react";
import styled from "@emotion/styled";
import dimensions from "styles/dimensions";
import colors from "styles/colors";
import PropTypes from "prop-types";

const ProjectCardContainer = styled("div")`
    box-shadow: 0px 9px 24px rgba(0, 0, 0, 0.06);
    transition: all 150ms ease-in-out;
    text-decoration: none;
    color: currentColor;

    @media(max-width:${dimensions.maxwidthMobile}px) {
        margin-bottom: 2em;
    }

    a {
        text-decoration: inherit;
        color: inherit;
    }

    &:hover {
        box-shadow: 0px 9px 24px rgba(0, 0, 0, 0.1);
        transition: all 150ms ease-in-out;

        .ProjectCardAction {
            color: ${colors.red};
            transition: all 150ms ease-in-out;

            span {
                transform: translateX(0px);
                opacity: 1;
                transition: transform 150ms ease-in-out;
            }
        }

        .ProjectCardContent::before {
            opacity: 0.02;
            transition: all 150ms ease-in-out;
        }

        .ProjectCardImageContainer::before {
            opacity: 0.2;
            transition: all 150ms ease-in-out;
        }
    }
`

const ProjectCardContent = styled("div")`
    background: ${colors.green};
    padding: 1.9em;
    position: relative;

    &:before {
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background: ${colors.blue500};
        mix-blend-mode: multiply;
        opacity: 0;
        transition: all 150ms ease-in-out;
    }

    @media(max-width:950px) {
        padding: 3.25em 2.5em 2em 2.5em;
    }

    @media(max-width:${dimensions.maxwidthTablet}px) {
        grid-row: 2;
    }
`

const ProjectCardTitle = styled("h3")`
    color: ${colors.yellow};
    margin-bottom: 0.5em;
    margin-top: 0.5em;
`

const ProjectCardAction = styled("div")`
    font-weight: 600;
    text-decoration: none;
    color: ${colors.dark};
    transition: all 150ms ease-in-out;

    span {
        margin-left: 1em;
        transform: translateX(-8px);
        display: inline-block;
        transition: transform 400ms ease-in-out;
    }
`

const ReportCard = ({ title, year, file}) => (
        <ProjectCardContainer>
            <a href={file}
            target="_blank" rel="noopener noreferrer">
                <ProjectCardContent className="ProjectCardContent">
                    <ProjectCardTitle>
                        {title[0].text}
                    </ProjectCardTitle>
                    <ProjectCardAction className="ProjectCardAction">
                        Atidaryti <span>&#8594;</span>
                    </ProjectCardAction>
                </ProjectCardContent>
            </a>
        </ProjectCardContainer>
)

export default ReportCard;

ReportCard.propTypes = {
    title: PropTypes.array.isRequired,
    year: PropTypes.string.isRequired,
    file: PropTypes.string.isRequired
}