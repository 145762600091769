import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import styled from "@emotion/styled";
import dimensions from "styles/dimensions";
import Layout from "components/Layout";
import ReportCard from "components/ReportCard";

const ReportTitle = styled("h1")`
    margin-bottom: 1em;
`

const ReportsYear = styled("h2")`
    text-align: center;
`

const ReportsGrid = styled("div")`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 2.5em;

    @media(max-width: 1050px) {
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 1.5em;
    }

    @media(max-width: ${dimensions.maxwidthMobile}px) {
        grid-template-columns: 1fr;
        grid-gap: 2.5em;
    }
`

const Ataskaitos = ({ home, groupedReports, meta }) => (
    <>
        <Helmet
            title={`Ataskaitos | Antanavo bendruomenė`}
            titleTemplate={`%s | Ataskaitos | Antanavo bendruomenė`}
            meta={[
                {
                    name: `description`,
                    content: meta.description,
                },
                {
                    property: `og:title`,
                    content: `Ataskaitos | Antanavo bendruomenė`,
                },
                {
                    property: `og:description`,
                    content: meta.description,
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    property: `og:image`,
                    content: home.hero_image.url,
                },
                {
                    property: `og:url`,
                    content: meta.siteurl+`/ataskaitos`,
                },
                {
                    property: `fb:app_id`,
                    content: meta.fbappid,
                },
                {
                    name: `twitter:card`,
                    content: `summary_large_image`,
                },
                {
                    name: `twitter:creator`,
                    content: meta.author,
                },
                {
                    name: `twitter:title`,
                    content: meta.title,
                },
                {
                    name: `twitter:description`,
                    content: meta.description,
                },  
            ].concat(meta)}
        >
            <html lang="lt" />
        </Helmet>
        <Layout>
            <ReportTitle>
                Ataskaitos
            </ReportTitle>
            
            {
                Object.keys(groupedReports).sort((firstYear, secondYear) => secondYear - firstYear).map(year => ( 
                    <Fragment key={year}>
                        <ReportsYear>{year}</ReportsYear>
                        <ReportsGrid> 
                            {
                                groupedReports[year].map((report, i) => ( 
                                    <ReportCard key = {i}
                                        title = {report.node.report_title}
                                        year = {report.node.report_year}
                                        file = {report.node.report_file.url}
                                    />))
                            }
                        </ReportsGrid>
                    </Fragment>
                    ))
                }
        </Layout>
    </>
);

export default ({ data }) => {
    const doc = data.prismic.allHomepages.edges.slice(0, 1).pop();
    const reports = data.prismic.allReports.edges;
    
    if (!reports) return null;
    
    const reportsGrouped = reports.reduce((agg, report) => {
        const year = report.node.report_year;

        if(!agg[year]) agg[year] = [report]
        else if(agg[year]) agg[year].push(report)

        return agg;
    }, {});

    const meta = data.site.siteMetadata;

    return (
        <Ataskaitos home={doc.node} groupedReports={reportsGrouped} meta={meta}/>
    )
}

Ataskaitos.propTypes = {
    home: PropTypes.object.isRequired,
    groupedReports: PropTypes.object.isRequired,
};

export const query = graphql`
    {
        prismic {
            allHomepages(first: 1) {
                edges {
                    node {
                        hero_image
                    }
                }
            }
            allReports(sortBy:report_year_DESC, first: 100) {
                edges {
                    node {
                        report_title
                        report_year
                        report_file {
                            ... on PRISMIC__FileLink {
                                _linkType
                                url
                            }
                        }
                        _meta {
                            uid
                        }
                    }
                }
            }
        }
        site {
            siteMetadata {
                title
                description
                author
                fbappid
                siteurl
            }
        }
    }
`

